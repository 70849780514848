import { WithId } from '@common/typescript/objects/WithId';
import { isUndefined } from '@common/react/utils/guards';

export interface Named {
	firstName: string;
	lastName: string;
}

interface Props {
	outerArray: string;
	innerArray: string;
}

type PartialOnNullValue<T> = {
	[P in keyof T]?: T[P] | null;
};

export type ClearValue<T> = {
	[P in keyof T]?: T[P] extends Array<infer V> ? Array<PartialOnNullValue<V>> : T[P] | null;
};

interface NamedWithEmail extends Named {
	email: string;
}

export const getUserName = (item: Named) => (item ? `${item.lastName || ''} ${item.firstName || ''}`.trim() : '');

export const getUserNameReverseOrder = (item: Named) => (item ? `${item.firstName || ''} ${item.lastName || ''}`.trim() : '');

export const getUserNameWithEmail = (item?: NamedWithEmail) => (item ? `${item.lastName || ''} ${item.firstName || ''} (${item.email})` : '');

export const imageSize = (s, size) => {
	return s ? s.replace(/.(\w+$)/, `${size}.$1`) : '';
};

export const sortByStringField = (field: string) => (a, b) => {
	if (a[field] < b[field]) {
		return -1;
	}

	if (a[field] > b[field]) {
		return 1;
	}

	return 0;
};
/* eslint-disable */
export const generateGUID = (): string => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = Math.random() * 16 | 0;
		const v = c === 'x' ? r : (r & 0x3 | 0x8);

		return v.toString(16);
	});
};
/* eslint-enable */

export const getQueryParams = (query: string, keys: Array<string>): object => {
	const params = new URLSearchParams(query);
	const result = {};
	keys.forEach((key) => result[key] = params.get(key));

	return result;
};

export const asInteger = (source: string | null, defaultValue: number = 1) => {
	return (source && parseInt(source, 10)) || defaultValue;
};

export const returnAfterSubmit = <TValues, TResponse extends WithId>(
	values: TValues,
	fields: Array<string | object> = [],
	response?: TResponse,
	properties: Array<string> = ['id'],
): TValues | object => {
	if (!fields.length) {
		return {};
	}

	const isArrayOfObjects = (value): boolean => value instanceof Array && value[0] instanceof Object && value[0].id;

	const valuesToReturn = {};
	for (const item of fields) {
		const isString = typeof item === 'string';
		const key = (isString ? item : Object.keys(item)[0]) as string;

		valuesToReturn[key] = values[key];

		if (response && isArrayOfObjects(valuesToReturn[key])) {
			valuesToReturn[key] = valuesToReturn[key].filter((value) => !value.deleted).map((obj, index) => {
				if (response[key] && response[key][index]) {
					const resultObj = {
						...obj,
						...(isString ? {} : returnAfterSubmit(obj, item[key], response[key][index], properties)),
					};

					Object.assign(resultObj, ...properties.filter((p) => !isUndefined(resultObj[p]))
						.map((p) => ({ [p]: response[key][index][p] })));

					return resultObj;
				}

				return obj;
			});
		}
	}
	return valuesToReturn;
};
