import React from 'react';

import Button, { ButtonProps } from '@common/react/components/Forms/Button';
import { useItemsProviderContext } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

interface Props extends ButtonProps {
	silent?: boolean;
	pageSize?: number;
	afterLoad?: () => void;
}

const LoadMoreButton: React.FC<Props> = ({
	children, silent, pageSize, afterLoad, ...props
}) => {
	const context = useItemsProviderContext();
	if (!context.state) throw 'Need ItemsProvider context!';
	const { state: { loading, items, pagination }, actions: { loadMore } } = context;
	const [isLoading, setIsLoading] = React.useState(false);
	if (items?.length === pagination?.total) {
		return null;
	}

	return <Button
		isLoading={loading || isLoading}
		{...props}
		onClick={() => {
			setIsLoading(true);
			loadMore({ pageSize }, false, silent)
				.then(() => {
					setIsLoading(false);
					afterLoad && afterLoad();
				});
		}}
	>
		{children}
	</Button>;
};

export default LoadMoreButton;
